<template>
  <div class="wrap wrapCardQuery">
    <Loading v-show="$store.state.isLoading"/>
    <div v-show="!$store.state.isLoading">
      <van-field
        readonly
        v-model="customername"
        name="customername"
        label="姓名"
      />
      <van-field
        readonly
        v-model="bank"
        name="bank"
        label="银行名称"
      />
      <van-field
        readonly
        v-model="cardno"
        name="cardno"
        label="银行卡号"
      />
      <van-col span="20" offset="2" class="footerBtnSpecial" @click="toPage" v-if='contract_status=="还款中" && isremove =="n"'>扣款卡变更</van-col>
    </div>
  </div>
</template>
<script>
  import moment from 'moment';
  import {cardinquirys,calculation,getOpenId,modifyCardInfo,getWxMenuCue,getImgToken} from '@/server'
  import { Toast,Dialog} from 'vant';
  import {setCookie,getCookie,domainUrl} from '@/lib/utils';
  import { formatMoney } from '@/lib/format';
  import { dealAccountNoHide } from '@/lib/format';
  import Loading from "@/components/Loading";
  export default {
    components: {
      Loading
    },
  data() {
    return {
      cardId:this.$store.state.cardId,
      accessToken:this.$store.state.accessToken,
      // openId:localStorage.getItem("openId") ||this.$store.state.openId,
      openId:getCookie('openId'),
      code:this.$route.query.code || '',
      contractNumber: this.$route.query.contractNumber || '',
      contype: this.$route.query.contype || '',
      cardno: '',
      bank: '',
      customername: '',
      contract_status: '',
      isremove:''//是否是终止合同 
    };
  }, 
  created(){
    sessionStorage.setItem('key', 0);
    //如果有openid
    if(this.openId){
      getOpenId({openid:this.openId}).then(res=>{
        if(res.wx_code=='0'){
          this.$store.commit('bindStatus', res.bind_status);
          localStorage.setItem("bindStatus", res.bind_status);
          localStorage.setItem("accessToken", res.access_token);
          this.$store.commit('accessToken', res.access_token);
         setCookie('openId',res.openid);
          this.$store.commit('openId', res.openid);
          this.$store.commit('cardId', res.card_id);
          localStorage.setItem("cardId", res.card_id);
          this.cardId = res.card_id;
          this.accessToken = res.access_token;
          this.openId = res.openid;
          if(res.bind_status !=='1'){
            this.$router.push({ path: '/login?redirect=/earlySettlementCalculation' });
          }else{
            this.getDetail();
          }
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    } else{
      if(!this.code) {
        this.$store.commit('bindStatus', '1');
        localStorage.setItem("bindStatus", '1');
        let uiCode = encodeURIComponent(domainUrl+'/earlySettlementCalculation');
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe40be82e1238dfe7&redirect_uri='+uiCode+'&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';         
      }else{//获取code
        getOpenId({percode:this.code}).then(res=>{
          if(res.wx_code=='0'){
            this.$store.commit('bindStatus', res.bind_status);
            localStorage.setItem("bindStatus", res.bind_status);
            this.$store.commit('accessToken', res.access_token);
            localStorage.setItem("accessToken", res.access_token);
            this.$store.commit('openId', res.openid);
           setCookie('openId',res.openid);
            this.$store.commit('cardId', res.card_id);
            localStorage.setItem("cardId", res.card_id);
            this.cardId = res.card_id;
            this.accessToken = res.access_token;
            this.openId = res.openid;
            if(res.bind_status !=='1'){
              this.$router.push({ path: '/login?redirect=/earlySettlementCalculation' });
            }else{
              this.getDetail();
            }
          }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          }
        });
      }
    }  
  },
  methods: {
    // getImgToken(){
    //   getImgToken({openid:this.openId}).then(res=>{
    //     if(res.wx_code=='0'){
    //       localStorage.setItem("token", res.img_token);
    //     }
    //   })
    // },
    getDetail(){
      const params = {
        contract_number:this.contractNumber,
        contype:this.contype
      }
      cardinquirys(params).then(res=>{
        if(res.wx_code=='200'){
          this.$store.commit('writeData', res);
          this.bank = res.bank
          this.cardno = res.cardno
          this.cardno = this.formatAccountNo(this.cardno)
          this.customername = res.customername
          this.contract_status = res.contract_status;
          this.isremove = res.isremove
          // this.getImgToken();
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
      
    },
    //隔四个空格格式化
    // formatAccountNo(value){
    //   return value.replace(/\s/g,'').replace(/(\d{4})(?=\d)/g,"$1 ");; 
    // },
    //带星格式化
    formatAccountNo(value, dot, digits){
      return dealAccountNoHide(value, dot, digits)
    },
    toPage(){
      const params = {//扣款卡变更
        data:{
          code:'menu_011'
        }
      }
      getWxMenuCue(params).then(res=>{
        if(res.status == '200'){
          if(res.data.isOpen=='0'){
            Dialog.alert({
              message: res.data.cue
            });
          }else{
            this.$store.commit('backFlagNum', this.contractNumber)
            this.$store.commit('backFlag', '2')
            this.$router.push({ path: '/modifyDebitCard' });
          }
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
      // const params = {
      //   access_token:this.accessToken,
      //   card_id:this.cardId
      // }
      // modifyCardInfo(params).then(res=>{
      //   Toast.clear();
      //   if(res.status=='200'){
      //     this.$store.commit('dataListTotal', res.data);
          // this.$store.commit('backFlagNum', this.contractNumber)
          // this.$store.commit('backFlag', '2')
          // this.$router.push({ path: '/modifyDebitCard' });
      //   }else{
      //     Toast.fail({
      //       message: res.msg,
      //       duration:'3000'
      //     });
      //   }
      // })
    }
  },
};
</script> 
<style scoped>
.wrap{
  height: 100vh !important;
  /* padding: 20px 0px;*/
  background: #ffffff;
}
.van-button--default{
  border: 0px;
}
.tips{
  position: fixed;
  bottom: 20px;
  color:#808080;
  font-size:13px;
  /* margin-left: 12px; */
}
.tips p{
    margin: 6px 15px;
}
</style>
<style>
.footerBtnSpecial{
  /* width: 345px; */
  height: 45px;
  text-align: center;
  border: 1px solid #CCCCCC;
  border-radius: 45px;
  line-height: 45px;
  font-size: 17px;
  color: #333333;
  position:fixed;
  bottom:10px;
  /* margin: 0 20px; */
}
.wrapCardQuery .van-field__control{
  color: #666666 !important;
  text-align: right;
}
.mountWrap{
  padding-bottom: 10px;
}
.mountWrap .van-cell{
  padding: 4px 10px;
  overflow: hidden;
  color: #808080;
  font-size:15px !important;
}
</style>