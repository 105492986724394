// 格式化账户金额 example：1,121,121,111.00
export function formatMoney(s, n) {
  n = n > 0 && n <= 20 ? n : 2
  s = parseFloat((s + '').replace(/[^\d\.-]/g, '')).toFixed(n) + ''
  var l = s.split('.')[0].split('').reverse()
  var r = s.split('.')[1]
  var t = ''
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 === 0 && (i + 1) !== l.length ? ',' : '')
  }
  return t.split('').reverse().join('') + '.' + r
}
//格式化手机
export function mobileNoHide(value, dot, digits) {
  if (value != null && typeof value !== "string") {
    value = value.toString();
  }
  if (value == "" || value == null || value == undefined) {
    return "";
  } else {
    var temp;
    value = value.trim();
    var hideNumber = value.substring(3, value.length - 4);
    var star = "";
    for (var i = 0; i < hideNumber.length; i++) {
      star += "*";
    }
    if (value.length == 11) {
      temp = value.replace(hideNumber, star);
    } else {
      temp = value;
    }
  }
  return temp;
};
//格式化账号，使其格式为2312********6788;
export function dealAccountNoHide(cash, dot, digits) {
  if (cash != null && typeof cash !== "string") {
    cash = cash.toString();
  }
  if (cash == "" || cash == null || cash == undefined) {
    return "";
  } else {
    var temp;
    cash = cash.replace(/(^\s*)|(\s*$)/g, "");
    var hideNumber = cash.substring(4, cash.length - 4);
    var star = ""; //统一变为8个*号
    for (var i = 0; i < hideNumber.length; i++) {
      star += "*";
    }
    if (cash.length >= 12) {
      temp = cash.replace(hideNumber, star);
    } else {
      temp = cash;
    }
  }
  return temp;
};
  